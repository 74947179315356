import { useEffect, useState } from 'react';

import useRequest from './useRequest';

const useFavouriteModal = () => {
  const { country } = useRequest();
  const shouldOpenInCountry = ['RUS', 'KAZ'].includes(country);
  const [shouldModalOpen, setShouldModalOpen] = useState(true);

  useEffect(() => {
    const handleStorageChange = () => {
      setShouldModalOpen(
        localStorage.getItem('shouldFavouriteAuthModalOpen') !== 'false' && shouldOpenInCountry,
      );
    };

    handleStorageChange();

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [shouldOpenInCountry]);

  const disableModalOpen = () => {
    if (localStorage && window) {
      localStorage.setItem('shouldFavouriteAuthModalOpen', 'false');
      setShouldModalOpen(false);
      window.dispatchEvent(new Event('storage'));
    }
  };

  return { shouldModalOpen, disableModalOpen };
};

export default useFavouriteModal;
