import { images } from './images';

import type { TagData, TagType } from './LinearTag';
import type { CountryCode, LanguageCode } from '@Types/Base';

type TagsData = Partial<Record<TagType, TagData[]>>;
type LocalData = Partial<Record<LanguageCode, TagsData>>;

const tags: Partial<Record<CountryCode, LocalData>> = {
  RUS: {
    ru: {
      extra: [
        {
          icon: images.fire,
          color: '#ffddca',
          text1: 'Экстраскидка',
          text2: 'до 20 октября',
          startData: new Date(2024, 9, 10, 0, 0, 0),
          endDate: new Date(2024, 9, 20, 23, 59, 59),
        },
        {
          icon: images.fire,
          color: '#ffddca',
          text1: 'Экстраскидка',
          text2: 'до 5 ноября',
          startData: new Date(2024, 9, 21, 0, 0, 0),
          endDate: new Date(2024, 10, 5, 23, 59, 59),
        },
        {
          icon: images.fire,
          color: '#ffddca',
          text1: 'Экстраскидка',
          text2: 'до 13 ноября',
          startData: new Date(2024, 10, 6, 0, 0, 0),
          endDate: new Date(2024, 10, 13, 23, 59, 59),
        },
        {
          icon: images.fire,
          color: '#ffddca',
          text1: 'Новая коллекция',
          text2: 'Новая коллекция',
          startData: new Date(2024, 10, 14, 0, 0, 0),
          endDate: new Date(2024, 11, 4, 31, 59, 59),
        },
      ],
      kits: [
        {
          icon: images.plus,
          color: '#F0E5CF',
          text1: 'Вместе дешевле',
          text2: 'Кровать + Матрас',
          startData: new Date(2024, 8, 13, 10, 0, 0),
          endDate: new Date(2024, 9, 8, 23, 59, 59),
        },
        {
          icon: images.plus,
          color: '#F0E5CF',
          text1: 'Вместе дешевле',
          text2: 'Кровать + Матрас',
          startData: new Date(2024, 9, 16, 10, 0, 0),
          endDate: new Date(2024, 10, 4, 23, 59, 59),
        },
        {
          icon: images.plus,
          color: '#F0E5CF',
          text1: 'Вместе дешевле',
          text2: 'Кровать + Матрас',
          startData: new Date(2024, 10, 8, 10, 0, 0),
          endDate: new Date(2024, 11, 3, 23, 59, 59),
        },
      ],
    },
  },
  KAZ: {
    ru: {
      extra: [
        {
          icon: images.fire,
          color: '#ffddca',
          text1: 'Экстраскидка',
          text2: 'до 9 октября',
          startData: new Date(2024, 8, 26, 0, 0, 0),
          endDate: new Date(2024, 9, 9, 23, 59, 59),
        },
        {
          icon: images.fire,
          color: '#ffddca',
          text1: 'Экстраскидка',
          text2: 'до 20 октября',
          startData: new Date(2024, 9, 10, 0, 0, 0),
          endDate: new Date(2024, 9, 20, 23, 59, 59),
        },
        {
          icon: images.fire,
          color: '#ffddca',
          text1: 'Экстраскидка',
          text2: 'до 5 ноября',
          startData: new Date(2024, 9, 21, 0, 0, 0),
          endDate: new Date(2024, 10, 5, 23, 59, 59),
        },
        {
          icon: images.fire,
          color: '#ffddca',
          text1: 'Экстраскидка',
          text2: 'до 13 ноября',
          startData: new Date(2024, 10, 6, 0, 0, 0),
          endDate: new Date(2024, 10, 13, 23, 59, 59),
        },
      ],
    },
    kk: {
      extra: [
        {
          icon: images.fire,
          color: '#ffddca',
          text1: 'Экстраскидка',
          text2: '9 қазанға дейін',
          startData: new Date(2024, 8, 26, 0, 0, 0),
          endDate: new Date(2024, 9, 9, 23, 59, 59),
        },
        {
          icon: images.fire,
          color: '#ffddca',
          text1: 'Экстраскидка',
          text2: '20 қазанға дейін',
          startData: new Date(2024, 9, 10, 0, 0, 0),
          endDate: new Date(2024, 9, 20, 23, 59, 59),
        },
        {
          icon: images.fire,
          color: '#ffddca',
          text1: 'Экстраскидка',
          text2: '5 қарашаға дейін',
          startData: new Date(2024, 9, 21, 0, 0, 0),
          endDate: new Date(2024, 10, 5, 23, 59, 59),
        },
        {
          icon: images.fire,
          color: '#ffddca',
          text1: 'Экстраскидка',
          text2: '13 қарашаға дейін',
          startData: new Date(2024, 10, 6, 0, 0, 0),
          endDate: new Date(2024, 10, 13, 23, 59, 59),
        },
      ],
    },
  },
};

export default tags;
